import React, { useState, useContext } from 'react';
import './SurgeRoller.css';
import RollSurge from './RollSurge';
import History from './History';
import Edit from './Edit';
import { UserContext } from "../../providers/UserProvider";
import { auth } from "../../firebase";
import SignIn from './SignIn';

// Enum to define loadable components
const ROUTES = Object.freeze({
    ROLL_SURGE: 'surgeBody',
    HISTORY: 'history',
    EDIT: "editSurges"
  });

// Handle component loading
function SurgeRollerApp() {
    const user = useContext(UserContext);
    const [component, setComponent] = useState(<RollSurge />);

    const handleNavigation = (e) => {
        switch(e) {
            case ROUTES.ROLL_SURGE:
                setComponent(<RollSurge />);
                break;
            case ROUTES.HISTORY:
                setComponent(<History />);
                break;
            case ROUTES.EDIT:
                setComponent(<Edit />);
                break;
            default:
                setComponent(<RollSurge />);
                break;
        }
    };

    return (
        user ?
        <div className="container">
            <h1>SurgeRoller</h1>
            {component}
            <nav className="navBlock">
                <button className="buttonLight" onClick={() => handleNavigation(ROUTES.ROLL_SURGE)}>Roller</button>
                <button className="buttonLight" onClick={() => handleNavigation(ROUTES.HISTORY)}>History</button>
                <button className="buttonLight" onClick={() => handleNavigation(ROUTES.EDIT)}>Edit Surges</button>
                <button className="buttonLight" onClick={() => auth.signOut()}>Sign Out</button>
            </nav>
        </div>
        : <SignIn />
    );
    
}

export default SurgeRollerApp;