import React from 'react';
import './App.css';
import UserProvider from './providers/UserProvider';
import SurgeRollerApp from './Components/pages/SurgeRollerApp';

function App() {
  return (
    <UserProvider>
      <SurgeRollerApp />
    </UserProvider>
  );
}

export default App;
