import React, {useState, useEffect, useContext} from 'react';
import './SurgeRoller.css';
import SurgeItem from '../helper/SurgeItem';
import firebase from '../../firebase.js';
import Modal from '../helper/Modal';
import { UserContext } from "../../providers/UserProvider";

function History() {
    const user = useContext(UserContext);
    const DATA_ROUTES = Object.freeze({
        SURGES: `users/${user.uid}/surges`,
        HISTORY: `users/${user.uid}/history`
    });
    const [surges, setSurges] = useState([]);
    const [displayModal, setDisplayModal] = useState(false);

    useEffect(() => {
        // Load all surges from history
        const historyRef = firebase.database().ref(DATA_ROUTES.HISTORY);
        historyRef.on('value', (snapshot) => {
                let history = snapshot.val();
                let newState = [];
                for (let surge in history) {
                    newState.push(
                        {
                            id: surge,
                            value: history[surge].surge
                        }
                    );
                }

                setSurges(newState);
            });

        return () => {
            historyRef.off('value');
          }
        }, [DATA_ROUTES.HISTORY]
    );

    // Delete a surge
    const handleDelete = (surge) => {
        return firebase.database().ref(DATA_ROUTES.HISTORY).child(surge).remove();
    };

    // Delete all surges in history
    const handleDeleteAll = (proceed) => {
        toggleDeleteAllModal();
        if(proceed) {
            return firebase.database().ref(DATA_ROUTES.HISTORY).remove();
        }
    };

    // Delete all dialog
    const toggleDeleteAllModal = (e) => {
        setDisplayModal(displayModal ? false : true);
    };

    // Update surge
    const handleEdit = (surgeId, surge) => {
        firebase.database().ref(DATA_ROUTES.HISTORY).child(surgeId).update({surge: surge.value})
    };

    return (
        <>
        <div className="bodyContainer">
            <ul className="surgeList">
                {surges.map((surge) => { 
                    return (
                        <SurgeItem surge={surge} handleRemove={handleDelete} key={surge.id} handleEdit={handleEdit} />
                    )
                })}
            </ul>
        </div>
        <nav className="navBlock">
            <button className="buttonDark" onClick={toggleDeleteAllModal}>Delete All</button>
        </nav>
        <Modal displayModal={displayModal} toggleModal={toggleDeleteAllModal} onProceed={handleDeleteAll}>
            This cannot be undone. Delete all surges?
            <br />
        </Modal>
        </>
    );
}

export default History;