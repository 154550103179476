import React, {useState, useEffect, useRef, useContext} from 'react';
import './SurgeRoller.css';
import SurgeItem from '../helper/SurgeItem';
import firebase from '../../firebase.js';
import Modal from '../helper/Modal';
import { UserContext } from "../../providers/UserProvider";

function Edit() {
    const user = useContext(UserContext);
    const DATA_ROUTES = Object.freeze({
        SURGES: `users/${user.uid}/surges`,
        HISTORY: `users/${user.uid}/history`
    });
    const [surges, setSurges] = useState([]);
    const inputFile = useRef(null);
    const [displayDeleteAllModal, setDisplayDeleteAllModal] = useState(false);
    const [displayNewSurgeModal, setDisplayNewSurgeModal] = useState(false);
    const [newSurgeValue, setNewSurgeValue] = useState("Enter new surge text.");

    useEffect(() => {
        // Load surges
        const surgesRef = firebase.database().ref(DATA_ROUTES.SURGES);
        surgesRef.on('value', (snapshot) => {
                let history = snapshot.val();
                let newState = [];
                for (let surge in history) {
                    newState.push(
                        {
                            id: surge,
                            value: history[surge].surge
                        }
                    );
                }

                setSurges(newState);
            });

        return () => {
            surgesRef.off('value');
          }
        }, [DATA_ROUTES.SURGES]
    );
    
    // Add a surge
    const handleAdd = (proceed) => {
        toggleNewSurgeModal();
        if(proceed) {
            firebase.database().ref(DATA_ROUTES.SURGES).push({surge: newSurgeValue});
            setNewSurgeValue("Enter new surge text.")
        }
    };

    // New surge dialog
    const toggleNewSurgeModal = () => {
        setDisplayNewSurgeModal(displayNewSurgeModal ? false : true);
    };

    // Delete surge
    const handleDelete = (surgeId) => {
        return firebase.database().ref(DATA_ROUTES.SURGES).child(surgeId).remove();
    };

    // Delete all dialog
    const toggleDeleteAllModal = () => {
        setDisplayDeleteAllModal(displayDeleteAllModal ? false : true);
    };

    // Delete all surges in history
    const handleDeleteAll = (proceed) => {
        toggleDeleteAllModal();
        if(proceed) {
            return firebase.database().ref(DATA_ROUTES.HISTORY).remove();
        }
    };

    // Update surge
    const handleEdit = (surgeId, surge) => {
        firebase.database().ref(DATA_ROUTES.SURGES).child(surgeId).update({surge: surge.value})
    };

    // Show the file select dialog
    const handleFileSelect = () => {
        // `current` points to the mounted file input element
       inputFile.current.click();
    };

    // When a file is selected
    function onChangeFile(event) {
        // Select the file
        event.stopPropagation();
        event.preventDefault();
        var file = event.target.files[0];

        // Read incoming data into array
        const fr = new FileReader();
        fr.onload = receivedText;
        fr.readAsText(file);
  
        function receivedText(e) {
            let lines = e.target.result;
            var newArr = JSON.parse(lines);

            importSurges(newArr);
        }
    };

    // Add array of surges to the database
    const importSurges = (surges) => {
        surges.MagicSurges.forEach(s => {
            firebase.database().ref(DATA_ROUTES.SURGES).push({surge: s});
        })
    };

    // Download surges to file
    const handleDownload = () => {
        // Create content
        const bb = new Blob([JSON.stringify({MagicSurges: surges.map(s => s.value)}, null, 2)], {type : 'application/json'});
        var a = document.createElement('a');
            
        // Initiate download
        a.download = 'surges.json';
        a.href = window.URL.createObjectURL(bb);
        a.target = "_blank"
        a.click();
        window.URL.revokeObjectURL(a.href);
    };

    return (
        <>
        <input type='file' id='file' ref={inputFile} style={{display: 'none'}} onChange={onChangeFile}/>
        <div className="bodyContainer">
            <ul className="surgeList">
                {surges.map((surge) => { 
                    return (
                        <SurgeItem surge={surge} handleRemove={handleDelete} key={surge.id} handleEdit={handleEdit} />
                    )
                })}
            </ul>
        </div>
        <nav className="navBlock">
            <button className="buttonDark" onClick={toggleNewSurgeModal}>+New Surge</button>
            <button className="buttonDark" onClick={handleFileSelect}>Import</button>
            <button className="buttonDark" onClick={handleDownload} id="download">Export</button>
            <button className="buttonDark" onClick={toggleDeleteAllModal}>Delete All</button>
        </nav>
        <Modal displayModal={displayDeleteAllModal} toggleModal={toggleDeleteAllModal} onProceed={handleDeleteAll}>
            This cannot be undone. Delete all surges?
            <br />
        </Modal>
        <Modal displayModal={displayNewSurgeModal} 
            toggleModal={toggleNewSurgeModal} 
            onProceed={handleAdd} 
            displayTextArea={true} 
            textValue={newSurgeValue}
            onTextChange={setNewSurgeValue}>
            <h2>New Magic Surge</h2>
        </Modal>
        </>
    );
}

export default Edit;