import firebase from 'firebase'
import "firebase/auth";

const config = {
    apiKey: "AIzaSyCD0SokhC_mLGHp8djWbBomFQc9Gm-WoGo",
    authDomain: "surgeroller.firebaseapp.com",
    databaseURL: "https://surgeroller.firebaseio.com",
    projectId: "surgeroller",
    storageBucket: "surgeroller.appspot.com",
    messagingSenderId: "834044807851",
    appId: "1:834044807851:web:b55382b341e6e5834d45ee"
};

firebase.initializeApp(config);
export default firebase;
export const auth = firebase.auth();