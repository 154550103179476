import React, {useState, useEffect, useContext} from 'react';
import './SurgeRoller.css';
import firebase from '../../firebase.js';
import { UserContext } from "../../providers/UserProvider";

function RollSurge(props) {
    const user = useContext(UserContext);
    const DATA_ROUTES = Object.freeze({
        SURGES: `users/${user.uid}/surges`,
        HISTORY: `users/${user.uid}/history`
    });
    const [surgeText, setSurgeText] = useState("Select 'Roll Surge' to begin.");
    const [surges, setSurges] = useState();

    useEffect(() => {
        // Load surges
        const surgeRef = firebase.database().ref(DATA_ROUTES.SURGES);
        surgeRef.on('value', (snapshot) => {
            let history = snapshot.val();
            let newState = [];
            for (let surge in history) {
                newState.push(
                    {
                        id: surge,
                        value: history[surge].surge
                    }
                );
            }
    
            setSurges(newState);
        });

        return () => {
            surgeRef.off('value');
          }
        }, [DATA_ROUTES.SURGES]
    );

    function rollSurge() {
        if(surges.length > 0){
            const surge = surges[Math.floor(Math.random() * surges.length)];

            setSurgeText(surge.value);

            const history = firebase.database().ref(DATA_ROUTES.HISTORY);
            history.push({surge: surge.value});
        }
        else {
            setSurgeText("No surges defined! Add one via \"Edit Surges\".");
        }
    }

    return (
        <>
        <div className="bodyContainer">
            <div className="surgeContainer fill">{surgeText}</div>
        </div>
        <nav className="navBlock">
            <button className="buttonDark" onClick={rollSurge}>Roll Surge</button>
        </nav>
        </>
    );
}

export default RollSurge;