import React, { useState } from 'react';
import './SurgeItem.css';

function SurgeItem(props) {
    const [surgeValue, setSurgeValue] = useState(props.surge.value);

    const onChange = (id, e) => {
        props.handleEdit(id, e.target);
        setSurgeValue(e.target.value);
    };

    // Unfocus textarea on enter key
    const onKeyDown = (id, e) => {
        var code = (e.keyCode ? e.keyCode : e.which)

        if(code === 13) {
            e.target.blur();
        }
    };

    return (
        <div className="surgeItemContainer">
            <li data-tag={props.surge.id} className="surgeItem">
                <textarea
                    rows="1"
                    value={surgeValue}
                    className="surgeInput"
                    onChange={(e) => onChange(props.surge.id, e)}
                    onKeyDown={(e) => onKeyDown(props.surge.id, e)}
                    maxLength={1000}
                />
            </li>
            <div onClick={() => props.handleRemove(props.surge.id)} className="deleteButton">X</div>
        </div>
    );
}

export default SurgeItem;