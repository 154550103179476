import React, { useCallback }from 'react';
import './Modal.css';

// Modal popup
// Sets props.onProceed to true if Proceed is selected
// Optionally display a textarea 
function Modal(props) {
    const handleProceed = useCallback(event => {
        props.onProceed(true);
      }, [props]);

    const handleInputChange = useCallback(event => {
    props.onTextChange(event.target.value)
    }, [props]);

    if (props.displayModal){
        return (
            <>   
            <div className="modal">
                <div className="modal-content">
                    <span className="close" onClick={props.toggleModal}>&times;</span>
                    {props.children}
                    {props.displayTextArea ? 
                        (<textarea value={props.textValue} onChange={handleInputChange} className="modalTextArea" rows="3" />) 
                        : (null)}
                    <button className="surgeButton" onClick={handleProceed} id="proceed">Proceed</button><button className="surgeButton" onClick={props.toggleModal}>Close</button>
                </div>
            </div>
            </>
        );
    }
    else {
        return null;
    }
}

export default Modal;